.requestLoader {
  position: absolute;
  top: 0;
  left: 0;

}

.movesCounter {
  font-family: "Oswald", sans-serif;
  font-weight: bolder;
  font-size: 36px;
}

.flip-container .flipper.flipped {
  transform: rotateY(180deg);
  cursor: not-allowed;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 0;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
  -webkit-transition-property: opacity, transform, box-shadow;
  -webkit-transition-duration: .3s;
  -webkit-backface-visibility: hidden;
}

.back {
  transform: rotateY(180deg);
}
